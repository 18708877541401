import { bet_form } from "./forms/bet.js";
import { give_form } from "./forms/giveaway.js";

import { check_donation_form } from "./forms/donation.js";
import { check_profile_form } from "./forms/profile.js";
// import { news_filter } from "./news/filter.js";
import { lot_category_filter } from "./lot/lot_category_filter.js";
import { lot_items_filter } from "./lot/lot_items_filter.js";

import { tabs as profile_tabs } from "./profile/tabs.js";


const init = () => {
    bet_form();
    give_form();

    check_donation_form();
    check_profile_form();
    // news_filter();

    lot_category_filter();
    lot_items_filter();

    document.querySelectorAll('.unfavorite').forEach((node) => {
        node.addEventListener('ajax:success', (event) => {
            event.target.closest('.mylot-line').remove();
        });
    });

    document.querySelectorAll('.btn-overlay.open-lots').forEach((btn) => {
        btn.addEventListener('ajax:beforeSend', (event) => {
            const [xhr, option] = event.detail;
            xhr.setRequestHeader('X-Partial-Section', event.currentTarget.dataset.section)
        });
    });

    document.querySelectorAll('.lots-like').forEach((btn) => {
        btn.addEventListener('ajax:success', (event) => {
            const data = event.detail[0];
            const target = event.currentTarget;
            target.setAttribute('href', data.url);
            data.isFavorite ? target.classList.add('favorite') : target.classList.remove('favorite');
        });
    });

    (function(){
        const readed = document.getElementById('readed');
        if (!readed) {
            return;
        }
        readed.addEventListener('click', async (event) => {
            event.preventDefault();
            event.stopPropagation();

            const token = document.querySelector('meta[name="csrf-token"]').content;
            const payload = {
                readed: true,
            }

            let response = await fetch('/set_read_message', {
                method: 'POST',
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'X-CSRF-Token': token,
                },
                body: JSON.stringify(payload)
            });
            let result = await response.json();

            const notification = document.querySelector('.notification');
            if (notification) {
                notification.classList.remove('has_new');
                notification.querySelectorAll('.notification__message_online').forEach(x => x.classList.remove('notification__message_online'));
            }
            readed.checked = false;
        });
    })();

    // (function(){
    //     const search = window.location.search;
    //     if (search.indexOf('complete=1') > -1) {
    //         $('.overlay_done').fadeIn();
    //     }
    // })();

    profile_tabs();

};

export { init };
