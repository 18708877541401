const tabs = () => {
  const container = document.querySelector('.profile-tab-container');
  if (!container) {
    return;
  }

  let params = new URLSearchParams(window.location.search);
  const path = window.location.pathname;

  function setActive(id) {
    container.dataset.view = id;
    if (params.get('tab') !== id) {
      params.set('tab', id);
      window.history.replaceState({}, '', [path, params.toString()].join('?'));
    }
  }

  for (let node of container.querySelectorAll('.tab-lot')) {
    node.addEventListener('click', () => setActive(node.dataset.id));
  }

  if (params.has('tab')) {
    setActive(params.get('tab'));
  }

  if (params.has('pid')) {
    $('.overlay_done').fadeIn();
    params.delete('pid');
    window.history.replaceState({}, '', [path, params.toString()].join('?'));
  }
}

export {
  tabs
}
